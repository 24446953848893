import { Base64Coder } from "@guardtime/common/lib/coders/Base64Coder.js";
import { HexCoder } from "@guardtime/common/lib/coders/HexCoder.js";
import {getHashIdByName, getHashInfoById, getSignatureIdByName} from "./schemes.js";

function ERR(message, location) {
	this.message = message;
	this.location = location;
}

function decodeBinary(node) {
	try {
		switch (node.encoding) {
			case "hex":
				return HexCoder.decode(node.encoded.value);
			case "base64":
				return Base64Coder.decode(node.encoded.value);
			default:
				return undefined;
		}
	} catch (e) {
		console.error("Unable to decode binary:" + e);
	}

	return undefined;
}

const instructions = {
	'pushboolean': function (inst, buf) { buf.push(0x51, inst.arg.value? 0x01 : 0x00); },
	'pushinteger': function (inst, buf) {
		const hexVal = inst.arg.value >= 0
			? inst.arg.value.toString(16).padStart(16, '0')
			: (inst.arg.value>>>0).toString(16).padStart(16, 'f');
		buf.push(0x01, ...HexCoder.decode(hexVal));
	},
	'pushstring': function () { console.error('Not implemented'); },
	'pushpublickey': function (inst, buf) {
		const algoId = getSignatureIdByName(inst.arg.scheme.value);
		if (!algoId) {
			throw new ERR("Unsupported signature scheme: " + inst.arg.scheme.value, inst.arg.scheme.location);
		}
		const data = decodeBinary(inst.arg.publicKey);
		if (!data) {
			throw new ERR("Unable to decode public key value", inst.arg.publicKey.encoded.location);
		}

		buf.push(0x55, algoId);
		buf.push(...data);
	},
	'pushprivatekey': function (inst) {
		const algoId = getSignatureIdByName(inst.arg.scheme.value);
		if (!algoId) {
			throw new ERR("Unsupported signature scheme: " + inst.arg.scheme.value, inst.arg.scheme.location);
		}
		const data = decodeBinary(inst.arg.privateKey);
		if (!data) {
			throw new ERR("Unable to decode public key value", inst.arg.publicKey.encoded.location);
		}

		throw new ERR("Push private key not implemented", inst.location);
	},
	'pushsignature': function (inst, buf) {
		const algoId = getSignatureIdByName(inst.arg.scheme.value);
		if (!algoId) {
			throw new ERR("Unsupported signature scheme: " + inst.arg.scheme.value, inst.arg.scheme.location);
		}
		const data = decodeBinary(inst.arg.signature);
		if (!data) {
			throw new ERR("Unable to decode signature value", inst.arg.signature.encoded.location);
		}

		buf.push(0x54, algoId);
		buf.push(...data);
	},
	'pushhash': function (inst, buf) {
		const algoInfo = getHashInfoById(getHashIdByName(inst.arg.algo.value));
		if (!algoInfo) {
			throw new ERR("Unsupported hash algorithm: " + inst.arg.algo.value, inst.arg.algo.location);
		}
		const data = decodeBinary(inst.arg.hash);
		if (!data) {
			throw new ERR("Unable to decode hash value", inst.arg.hash.encoded.location);
		}

		if (algoInfo.len !== data.length) {
			throw new ERR("Invalid hash value length for " + inst.arg.algo.value, inst.arg.hash.encoded.location);
		}
		buf.push(0x4f, algoInfo.id);
		buf.push(...data);
	},

	'dup': function (inst, buf) { buf.push(0x76); },
	'not': function (inst, buf) { buf.push(0x91); },
	'drop': function (inst, buf) { buf.push(0x75); },
	'swap': function (inst, buf) { buf.push(0x7c); },
	'hash': function (inst, buf) {
		const algoId = getHashIdByName(inst.algo.value);
		if (!algoId) {
			throw new ERR("Unsupported hash algorithm: " + inst.algo.value, inst.algo.location);
		}
		buf.push(0xa8, algoId);
	},
	'equal': function (inst, buf) { buf.push(0x87); },
	'verify': function (inst, buf) { buf.push(0x69);},
	'checksignature': function (inst, buf) {
		buf.push(0xac);
		/* TODO! Is the signature scheme really necessary here? */
	},
	'if': function (inst, buf, env) {
		buf.push(0x63);
		buf.push(...compileBranch(inst.thenBranch, env));

		if (inst.elseBranch) {
			buf.push(0x67, ...compileBranch(inst.elseBranch, env));
		}

		buf.push(0x68);
	},
	'is': function (inst, buf, env) {
		if (inst.id in env.definitions) {
			throw new ERR("Refefinition of an identifier", inst.id.location);
		}

		env.definitions[inst.id] = inst.val;
	}
}

function compileBranch(ast, env) {
	const bin = [];
	env = env || {};
	env.definitions = env.definitions || {};

	for (let i = 0; i < ast.length; i++) {
		const inst = ast[i];
		if (!inst) continue;

		if (inst.type !== 'instruction') {
			throw 'Internal error, not an instruction: ' + JSON.stringify(inst);
		}
		if (inst.arg && inst.arg.type === "identifier") {
			const arg = env.definitions[inst.arg.name];
			if (!arg) {
				throw "Undeclared identifier '" + inst.arg.name + "'";
			}
			inst.arg = arg;
		}



		const key = inst.op.replace(' ', '') + (inst.arg ? inst.arg.type : '');
		if (typeof instructions[key] !== 'function') {
			throw 'Internal error, unknown instruction: ' + JSON.stringify(inst);
		}

		instructions[key](inst, bin, env);
	}

	return bin;
}

export function compile(ast, env) {
	return new Uint8Array([0x53, ...compileBranch(ast, env)]);
}
