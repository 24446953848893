import Logo from "../../assets/playground-logo.svg";

export default function Header(): JSX.Element {
  return (
    <header className="header">
      <div className="header__children">
        {" "}
        <img src={Logo} alt="Playground logo" height="40px" />
      </div>
    </header>
  );
}
