import {FormikValues} from "formik";
import nextId from "react-id-generator";
import {IBillProps} from "../../App";
import AddIco from "../../assets/add.svg";
import CloseIco from "../../assets/close.svg";
import {compileCode} from "../../utils/utils";
import Spacer from "../Spacer/Spacer";
import Bill from "./Bill";

export default function BillList(props: IBillProps): JSX.Element {

    const renderBannerButtons = (id: string) => {
        return (
            <div className="flex">
                <img
                    onClick={() =>
                        props.setGlobalState([
                            ...props.globalState,
                            {
                                id: nextId("bill"),
                                billValue: 100,
                                billPredicate: ''
                            },
                        ])
                    }
                    src={AddIco}
                    alt="Add"
                    height="32px"
                    className="button__icon"
                />
                {props.globalState.length > 1 && (
                    <>
                        <Spacer mr={4}/>
                        <img
                            onClick={() =>
                                props.setGlobalState(
                                    props.globalState.filter((item) => item.id !== id)
                                )
                            }
                            src={CloseIco}
                            alt="Add"
                            height="32px"
                            className="button__icon"
                        />
                    </>
                )}
            </div>
        );
    };

    return (
        <div>
            {props.globalState.map((item) =>
                <Bill key={item.id} state={item}
                      headerComponents={renderBannerButtons(item.id)}
                      compileCode={(value?: string) => compileCode(value, props.keys)}
                      onSubmit={async (values: FormikValues) => {
                          const updatedData = props.globalState.map((obj) => {
                              if (obj.id === item.id) {
                                  return {
                                      ...obj,
                                      billValue: values.billValue,
                                      billPredicate: values.bearerPredicate || ''
                                  };
                              } else return obj;
                          });

                          await props.setGlobalState(updatedData);
                      }}/>
            )}
        </div>
    );
}
