import {Formik, FormikValues} from "formik";
import React, {useState} from "react";
import * as Yup from "yup";
import {IGlobalState} from "../../App";
import VerificationIco from "../../assets/ico-verification.svg";
import {compileCode, extractFormikError} from "../../utils/utils";

import Banner from "../Banner/Banner";
import Button from "../Button/Button";
import {Form, FormContent, FormFooter} from "../Form/Form";
import {HexField} from "../HexField/HexField";
import Spacer from "../Spacer/Spacer";
import TextAreaField from "../TextAreaField/TextAreaField";
import Textfield from "../TextField/TextField";

export default function Bill(props: { state: IGlobalState, compileCode: (value?: string) => Uint8Array, headerComponents: JSX.Element, onSubmit: (values: FormikValues) => Promise<void> }): JSX.Element {
    const [hasError, setHasError] = useState(false);

    return (
        <Banner
            headerComponents={props.headerComponents}
            title="Bill"
        >
            <Formik
                enableReinitialize={true}
                validationSchema={Yup.object().shape({
                    billId: Yup.string().required("Bill ID is required."),
                    billValue: Yup.string().required("Signature is required.")
                })}
                initialValues={{
                    billId: props.state.id,
                    billValue: props.state.billValue,
                    bearerPredicate: props.state.billPredicate
                }}
                onSubmit={props.onSubmit}
            >
                {(formikProps) => {
                    const {handleSubmit, isSubmitting, errors, touched, values} =
                        formikProps;

                    return (
                        <form onSubmit={handleSubmit}>
                            <Form>
                                <FormContent>
                                    <Textfield
                                        id="billId"
                                        name="billId"
                                        label="ID"
                                        type="text"
                                        placeholder="Change Bill ID"
                                        error={extractFormikError(errors, touched, [
                                            "billId",
                                        ])}
                                        disabled
                                    />
                                    <Textfield
                                        id="billValue"
                                        name="billValue"
                                        label="Value"
                                        type="text"
                                        placeholder="Change Bill Signature"
                                        error={extractFormikError(errors, touched, [
                                            "billValue",
                                        ])}
                                    />
                                    <TextAreaField
                                        id="bearerPredicate"
                                        name="bearerPredicate"
                                        label="Bearer Predicate"
                                        type="text"
                                        rows={4}
                                        error={extractFormikError(errors, touched, [
                                            "bearerPredicate",
                                        ])}
                                    />
                                    <HexField
                                        value={values.bearerPredicate || ''}
                                        compileCode={(value: string | undefined) => compileCode( value || '', [])}
                                        onError={() => setHasError(true)}
                                        onSuccess={() => setHasError(false)}/>
                                </FormContent>
                                <FormFooter>
                                    <>
                                        <Spacer mt={32}/>
                                        <div className="button-ico-wrap">
                                            <Button type="submit" working={isSubmitting} disabled={(values.bearerPredicate === props.state.billPredicate && values.billValue === props.state.billValue) || hasError}>
                                                Save
                                            </Button>
                                            {values.bearerPredicate === props.state.billPredicate && values.billValue === props.state.billValue && (
                                                <>
                                                    <img
                                                        src={VerificationIco}
                                                        alt="Is Verified"
                                                        height="32px"
                                                    />
                                                    <div className="message">Saved</div>
                                                </>
                                            )}
                                        </div>
                                    </>
                                </FormFooter>
                            </Form>
                        </form>
                    );
                }}
            </Formik>

            {props.state.billStates?.length && (
                <>
                    <Spacer mt={32}/>
                    <b>Transaction History:</b>
                    <div>
                        {props.state.billStates.map((state) => {
                            return (
                                <div key={state.id} className="bill__state">
                                    <Spacer mt={8}/>
                                    {state.time}
                                        <div className="bill__signature">
                                            <b>Predicate:</b>
                                            <pre style={{ whiteSpace: 'pre-wrap'}}>{state.predicate}</pre>
                                        </div>
                                </div>
                            );
                        })}
                    </div>
                </>
            )}
        </Banner>
    );
}
