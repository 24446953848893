import {HexCoder} from "@guardtime/common/lib/coders/HexCoder";
// @ts-ignore
import {compile} from "../compiler";
// @ts-ignore
import {interpretBillScript, loadShiny, ShinyElse, ShinyEndIf, ShinyIf} from "../shiny";
import {getIn} from "formik";
import { Result } from "@guardtime/common/lib/verification/Result";

export const extractFormikError = (
    errors: unknown,
    touched: unknown,
    names: string[]
): string =>
    names
        .map((name) => {
            const error = getIn(errors, name);
            if (!error || typeof error !== "string") {
                return "";
            }

            return error ? error : "";
        })
        .find((error) => !!error) || "";

export const makeStateId = (length: number) => {
    let result = "";
    const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

const createKeyDefinitions = (keyProps: any[] | undefined) => {
    const keys = keyProps
        ?.filter((item) => item.privateKey && item.publicKey) || [];
    const definitions: Record<string, unknown> = {};
    for (const key of keys) {
        definitions[key.id + '.publicKey'] = {
            publicKey: {
                encoded: {
                    value: HexCoder.encode(key.publicKey as Uint8Array)
                },
                encoding: 'hex'
            },
            type: 'publickey',
            scheme: {
                value: key.keyAlgorithm
            }
        },
        definitions[key.id + '.privateKey'] = {
            privateKey: {
                encoded: {
                    value: HexCoder.encode(key.privateKey as Uint8Array)
                },
                encoding: 'hex'
            },
            type: 'privatekey',
            scheme: {
                value: key.keyAlgorithm
            }
        },
        definitions[key.id + '.sha256'] = {
            hash: {
                encoded: {
                    value: key.sha256
                },
                encoding: 'hex'
            },
            type: 'hash',
            algo: {
                value: 'sha256'
            }
        },
        definitions[key.id + '.sha512'] = {
            hash: {
                encoded: {
                    value: key.sha512
                },
                encoding: 'hex'
            },
            type: 'hash',
            algo: {
                value: 'sha512'
            }
        };
    }

    return definitions;
}

export const compileCode = (value?: string, keyProps?: any[], signature?: string) => {
    const definitions = {
        ...createKeyDefinitions(keyProps)
    };

    if (signature) {
        definitions['signature'] = {
            signature: {
                encoded: {
                    value: signature
                },
                encoding: 'hex'
            },
            type: 'signature',
            scheme: {
                value: 'ed25519'
            }
        };
    }

    // @ts-ignore
    return compile(window.ShinyParser.parse(value), {definitions});
}

export const interpretCode = async (a: number[], b: number[], env: unknown): Promise<Result<string>> => {
    return interpretBillScript(loadShiny(a), loadShiny(b), env);
}

export const decompile = (value: Uint8Array) => {
    const indent = (v: string, count: number) => {
        return "  ".repeat(count) + v;
    }
    const stringifyShiny = (cmd: any[], depth = 0): string[] => {
        const rows: string[] = [];
        for (const exec of cmd) {
            rows.push(indent(exec.toString(), depth));
            if (exec instanceof ShinyIf) {
                rows.push(...stringifyShiny(exec.when, depth + 1));
                if (exec.else.length > 0) {
                    rows.push(indent((new ShinyElse()).toString(), depth));
                    rows.push(...stringifyShiny(exec.else, depth + 1));
                }
                rows.push(indent((new ShinyEndIf()).toString(), depth));
            }
        }

        return rows;
    }

    return stringifyShiny(loadShiny(value)).join("\n");
}
