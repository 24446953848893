import {HexCoder} from "@guardtime/common/lib/coders/HexCoder";
import React, {useEffect, useState} from "react";

export const HexField = (props: { value: string, compileCode: (value?: string) => Uint8Array, onError?: (error?: string) => void, onSuccess?: () => void }) => {
    const [value, setValue] = useState<string | number>('');
    const [error, setError] = useState<string | undefined>(undefined);

    useEffect(() => {
        try {
            setValue(HexCoder.encode(props.compileCode(props.value as string)).replace(/(..)/g, "$1 "));
            setError(undefined);
            props.onSuccess?.();
        } catch (err) {
            // @ts-ignore
            const msg: string = !err.location ? (err?.message || err || '').toString() : `${err.location.start.line}:${err.location.start.column} ${err.message}`;
            setError(msg);
            props.onError?.(msg);
        }
    },[props]);


    if (error) {
        return (
            <span className="order-error">
              {error}
            </span>
        )
    }

    return (
        <>
            {value == 53 ? '' : value}
        </>
    );
};
