import { HexCoder } from "@guardtime/common/lib/coders/HexCoder";
import { Utf8Converter } from "@guardtime/common/lib/strings/Utf8Converter.js";
import { Result, ResultCode } from "@guardtime/common/lib/verification/Result";
import { sign, utils, verify } from "@noble/ed25519";
import { Formik } from "formik";
import moment from "moment";
import { useState } from "react";
import { IBillProps, IKeys } from "../../App";
import CloseIco from "../../assets/close.svg";
import VerificationIco from "../../assets/ico-verification.svg";
import {
  compileCode,
  decompile,
  extractFormikError,
  interpretCode,
  makeStateId,
} from "../../utils/utils";

import Banner from "../Banner/Banner";
import Button from "../Button/Button";
import { Form, FormContent, FormFooter } from "../Form/Form";
import { HexField } from "../HexField/HexField";
import Select from "../Select/Select";
import Spacer from "../Spacer/Spacer";
import TextAreaField from "../TextAreaField/TextAreaField";

export default function Order(props: IBillProps): JSX.Element {
  const [transactionStatus, setTransactionStatus] = useState<
    Result<string> | undefined
  >(undefined);
  const [signatureStatus, setSignatureStatus] = useState<
    { success: boolean; message: string } | undefined
  >(undefined);
  const [bearerSignatureCompileError, setBearerSignatureCompileError] =
    useState(false);
  const [newPredicateCompileError, setNewPredicateCompileError] =
    useState(false);

  const getSignedData = (billId: string, predicate: string) => {
    return Uint8Array.from(
      Utf8Converter.ToBytes(
        JSON.stringify({
          billId,
          predicate,
        })
      )
    );
  };

  return (
    <Banner title="Transaction Order">
      <Formik
        enableReinitialize
        initialValues={{
          bill: props.globalState[0],
          useKey: undefined as IKeys | undefined,
          bearerSignature: props.globalState[0]?.bearerSignature,
          newPredicate: props.globalState[0]?.newPredicate,
          signature: undefined as string | undefined,
        }}
        onSubmit={async (values, { setFieldValue }) => {
          try {
            const selectedKeyObj = values.useKey;
            // @ts-ignore
            const time = moment().format("YYYY-MM-DD HH:mm:ss");

            const newPredicateBytes = compileCode(
              values.newPredicate,
              props.keys
            );
            const result = await interpretCode(
              Array.from(
                compileCode(
                  values.bearerSignature,
                  props.keys,
                  values.signature
                )
              ),
              Array.from(compileCode(values.bill?.billPredicate)),
              {
                transactionOrder: getSignedData(
                  values.bill.id,
                  HexCoder.encode(newPredicateBytes)
                ),
              }
            );

            setTransactionStatus(result);
            if (result.getResultCode() !== ResultCode.OK) {
              return false;
            }

            const newPredicate = decompile(newPredicateBytes);
            props.setGlobalState(
              props.globalState.map((item) =>
                item.id === values?.bill?.id
                  ? {
                      ...item,
                      isValidSignature: item?.isValidSignature,
                      isSubmitted: true,
                      billPredicate: newPredicate,
                      publicKey: selectedKeyObj?.publicKey,
                      privateKey: selectedKeyObj?.privateKey,
                      transactionSignature: values.signature,
                      billStates: item?.billStates?.length
                        ? [
                            ...item.billStates,
                            {
                              time,
                              id: makeStateId(12),
                              signature: values.signature,
                              predicate: newPredicate
                            },
                          ]
                        : [
                            {
                              time,
                              id: makeStateId(12),
                              signature: values.signature,
                              predicate: newPredicate
                            },
                          ],
                    }
                  : item
              )
            );

            setSignatureStatus(undefined);
            setFieldValue("signature", "");
            return true;
          } catch (e) {
            setTransactionStatus(
              new Result("", ResultCode.FAIL, ((e as Error) || "").toString())
            );
          }

          return false;
        }}
      >
        {(formikProps) => {
          const {
            handleSubmit,
            isSubmitting,
            errors,
            touched,
            values,
            setFieldValue,
          } = formikProps;

          const currentBill = props.globalState?.find(
            (item) => item && item.id === values.bill.id
          );

          return (
            <form onSubmit={handleSubmit}>
              <Form>
                <FormContent>
                  <Select
                    label="Bill ID"
                    name="bill"
                    options={props.globalState.map((item) => ({
                      value: item,
                      label: item.id,
                    }))}
                    error={extractFormikError(errors, touched, ["bill"])}
                    onChange={() => {
                      setTransactionStatus(undefined);
                      setSignatureStatus(undefined);
                      setFieldValue("signature", "");
                    }}
                    value={currentBill}
                  />
                  <TextAreaField
                    id="bearerSignature"
                    name="bearerSignature"
                    label="Unlock Script"
                    type="text"
                    placeholder="Change Bearer Signature"
                    error={extractFormikError(errors, touched, [
                      "bearerSignature",
                    ])}
                    value={values.bill ? currentBill?.bearerSignature || "" : ""}
                  />
                  <HexField
                    value={values.signature ? values.bearerSignature : ""}
                    compileCode={(value: string | undefined) =>
                      compileCode(value || "", props.keys, values.signature)
                    }
                    onError={() => setBearerSignatureCompileError(true)}
                    onSuccess={() => setBearerSignatureCompileError(false)}
                  />
                  <TextAreaField
                    id="newPredicate"
                    name="newPredicate"
                    label="New Predicate"
                    type="text"
                    rows={5}
                    placeholder="Change New Predicate"
                    error={extractFormikError(errors, touched, [
                      "newPredicate",
                    ])}
                    onChange={() => setSignatureStatus(undefined)}
                    value={
                      values.bill
                        ? props.globalState?.find(
                            (item) => item.id === values.bill.id
                          )?.newPredicate || ""
                        : ""
                    }
                  />
                  <HexField
                    value={values.signature ? values.newPredicate : ""}
                    compileCode={(value: string | undefined) =>
                      compileCode(value || "", props.keys, values.signature)
                    }
                    onError={() => setNewPredicateCompileError(true)}
                    onSuccess={() => setNewPredicateCompileError(false)}
                  />
                  <Spacer mt={32} />
                  <Select
                    label="Use Key"
                    name="useKey"
                    options={props.keys
                      ?.filter((item) => item.privateKey && item.publicKey)
                      .map((item) => ({
                        value: item,
                        label: item.id,
                      }))}
                    isDisabled={
                      !props.keys?.filter(
                        (item) => item.privateKey && item.publicKey
                      )
                    }
                    value={
                      values.bill
                        ? props.keys
                            ?.filter(
                              (item) => item.privateKey && item.publicKey
                            )
                            .find(
                              (item) => item && item.id === currentBill?.useKey
                            )
                        : ""
                    }
                    onChange={() => {
                      setSignatureStatus(undefined);
                    }}
                    error={extractFormikError(errors, touched, ["useKey"])}
                  />
                  <TextAreaField
                    id="signature"
                    name="signature"
                    label="Transaction Signature"
                    type="text"
                    error={extractFormikError(errors, touched, ["signature"])}
                  />
                </FormContent>
                <FormFooter>
                  <>
                    <Spacer mt={48} />
                    <div className="button--group">
                      <div className="button-ico-wrap">
                        <Button
                          key="transaction-sign"
                          type="button"
                          disabled={
                            !values.useKey ||
                            !values.bill ||
                            newPredicateCompileError
                          }
                          onClick={async () => {
                            const selectedKeyObj = values.useKey;
                            if (
                              selectedKeyObj?.publicKey &&
                              selectedKeyObj?.privateKey
                            ) {
                              try {
                                const message = getSignedData(
                                  values.bill.id,
                                  HexCoder.encode(
                                    compileCode(values.newPredicate, props.keys)
                                  )
                                );

                                const signature = await sign(
                                  message,
                                  selectedKeyObj.privateKey
                                );
                                const isValid = await verify(
                                  signature,
                                  message,
                                  selectedKeyObj.publicKey
                                );

                                if (!isValid) {
                                  return setSignatureStatus({
                                    success: false,
                                    message: "Signature is invalid",
                                  });
                                }

                                setFieldValue(
                                  "signature",
                                  utils.bytesToHex(signature)
                                );
                                setSignatureStatus({
                                  success: true,
                                  message: "Signature is valid",
                                });
                                setTransactionStatus(undefined);
                              } catch (e) {
                                console.error(e);
                                setSignatureStatus({
                                  success: false,
                                  message: "Signing failed",
                                });
                              }
                            }
                          }}
                        >
                          Sign
                        </Button>
                      </div>
                      <div className="button-ico-wrap">
                        <Button
                          disabled={
                            !values.bill ||
                            newPredicateCompileError ||
                            bearerSignatureCompileError
                          }
                          type="submit"
                          working={isSubmitting}
                        >
                          Execute
                        </Button>
                      </div>
                    </div>
                    <Spacer mt={16} />
                    <div className="order-notification-group">
                      {signatureStatus !== undefined && (
                        <div className="flex flex-align-c order-notification">
                          <img
                            src={
                              signatureStatus.success
                                ? VerificationIco
                                : CloseIco
                            }
                            alt="Notification"
                            height="32px"
                          />
                          <div className="message">
                            {signatureStatus.message}
                          </div>
                        </div>
                      )}
                      {transactionStatus !== undefined && (
                        <div className="flex flex-align-c order-notification">
                          <img
                            src={
                              transactionStatus.getResultCode() ===
                              ResultCode.OK
                                ? VerificationIco
                                : CloseIco
                            }
                            alt="Notification"
                            height="32px"
                          />
                          <div className="message">
                            {transactionStatus.getResultCode() === ResultCode.OK
                              ? "Transaction successful"
                              : `Transaction failed: ${transactionStatus.getVerificationError()}`}
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                </FormFooter>
              </Form>
            </form>
          );
        }}
      </Formik>
    </Banner>
  );
}
