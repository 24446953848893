import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import { useEffect } from 'react';
import ReactSelect, { Props } from 'react-select';

export interface IOption {
  label: string;
  value: string;
}

export interface ISelectProps extends Props {
  name: string;
  className?: string;
  error?: string | string[];
  testId?: string;
  label: string;
  value?: any;
}

export default function Select(props: ISelectProps): JSX.Element {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props.name);

  useEffect(() => {
    props.value && setFieldValue(field.name, props.value);
  }, [props.value, field.name, setFieldValue]);

  const handleChange = (option: any): void => {
    setFieldValue(
      field.name,
      (option as IOption)?.value || ''
    );

    props.onChange?.(props.label, option.value);
  };

  const getValue = () => {
    if (props.options) {
      return (props.options as IOption[]).find((option: IOption) => option.value === field.value) || '';
    } else {
      return ('' as any);
    }
  };

  const customStyles = {
    option: (provided: any, state: any) => ({
      ...provided,
      background: state.isSelected ? '#0C0A3E' : state.isFocused ? '#0C0A3E' : 'rgba(255, 255, 255, 0.9)',
      color: state.isSelected ? 'white' : state.isFocused ? 'white' : 'hsl(0, 0%, 20%)',
      cursor: 'pointer',
    })
  }

  const className = classNames('select', { 'select--error': props.error }, props.className);

  return (
    <div className={className} data-testid={props.testId || 'select'}>
      {props.label && <label className="select__label">{props.label}</label>}
      <ReactSelect
        styles={customStyles}
        className="select__inner"
        placeholder={props.placeholder}
        name={props.value || field.name}
        value={getValue()}
        onChange={handleChange}
        options={props.options}
        menuPlacement="auto"
        classNamePrefix="select"
        isSearchable={false}
        components={{ IndicatorSeparator: null }}
        isDisabled={props.isDisabled}
      />
      {props.error && props.error.length > 0 && (
        <span data-testid="select__error" className="select__error">
          {props.error}
        </span>
      )}
    </div>
  );
}
