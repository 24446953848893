const hashAlgoName = {
    0x01: {name: 'sha256', len: 32},
    0x02: {name: 'sha512', len: 64},
}

const signatureSchemeName = {
    0x01: {name: 'ed25519', signatureLen: 64, pubKeyLen: 32}
}

const hashAlgorithmIds = {
    'sha256': 0x01,
    'sha512': 0x02,
}

const signatureAlgorithmIds = {
    'ed25519': 0x01,
}

export function getHashIdByName(algo) {
    if (!algo) return null;
    return hashAlgorithmIds[algo.toLowerCase()];
}

export function getSignatureIdByName(scheme) {
    if (!scheme) return null;
    return signatureAlgorithmIds[scheme.toLowerCase()];
}

export function getHashInfoById(id) {
    if (!id) {
        throw "No hash id provided.";
    }

    const info = hashAlgoName[id];
    if (!info) {
        throw "Unknown hash id 0x" + id.toString(16);
    }

    info.id = id;
    return info;
}

export function getSignatureSchemeInfoById(id) {
    if (!id) {
        throw "Signature scheme id missing";
    }

    const info = signatureSchemeName[id];
    if (!info) {
        throw "Unknown signature scheme id 0x" + id.toString(16);
    }

    info.id = id;

    return info;

}
