import React from "react";
import classNames from "classnames";

export interface IBannerProps {
  children: React.ReactNode;
  className?: string;
  title?: string;
  headerComponents?: React.ReactNode;
}

export default function Banner(props: IBannerProps): JSX.Element {
  const className = classNames("banner", props.className);

  return (
    <div className={className}>
      {props.title && (
        <div className="banner__header">
          <div>{props.title}</div>
          <div>{props.headerComponents}</div>
        </div>
      )}
      <div className="banner__children">{props.children}</div>
    </div>
  );
}
