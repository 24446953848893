import React from "react";
import classNames from "classnames";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { useField, useFormikContext } from "formik";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {ReactComponent as CopyIco} from "../../assets/copy.svg";

export interface ITextfieldProps {
  id: string;
  label: string;
  name?: string;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, name: string) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  value?: string;
  type?: string;
  placeholder?: string;
  required?: boolean;
  step?: string;
  hiddenPassword?: boolean;
  autoComplete?: string;
  readOnly?: boolean;
  transparent?: boolean;
  error?: string | string[];
  disabled?: boolean;
  pattern?: string;
  floatingFixedPoint?: string;
  isNumberFloat?: boolean;
  tooltip?: string;
  copyTooltip?: string;
  copyButtonValue?: string;
}

export default function Textfield(props: ITextfieldProps): JSX.Element {
  const {
    hiddenPassword,
    error,
    transparent,
    floatingFixedPoint,
    isNumberFloat,
    tooltip,
    copyTooltip,
    copyButtonValue,
    ...inputProps
  } = props;
  const { setFieldValue, handleBlur } = useFormikContext();
  const [field] = useField(props as any);

  const className = classNames(
    "textfield",
    {
      "textfield--hidden-password": hiddenPassword,
      "textfield--error": error,
      "textfield--transparent": transparent,
    },
    props.className
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regexNumber = /^[+-]?\d*(?:[.,]\d*)?$/;
    const fixedPoint = floatingFixedPoint || "2";
    const regexFloatString = "^\\d+(\\.\\d{0," + fixedPoint + "})?$";
    const regexFloat = new RegExp(regexFloatString);

    if (
      isNumberFloat &&
      e.target.value !== "" &&
      (!regexNumber.test(e.target.value) || !regexFloat.test(e.target.value))
    ) {
      return false;
    }

    props.onChange?.(e, field.name);
    setFieldValue(field.name, e.target.value);
  };

  return (
    <div className={className} data-testid="textfield">
      <div className="textfield__inner">
        {props.label && (
          <label
            className={classNames("textfield__label", {
              "with-tooltip": props.tooltip,
            })}
          >
            {props.label}
            {copyButtonValue && (
              <CopyToClipboard text={copyButtonValue}>
                <Tooltip
                  PopperProps={{
                    disablePortal: true,
                  }}
                  leaveDelay={300}
                  title={copyTooltip || ""}
                  placement="top-end"
                >
                  <CopyIco className="copy-btn" height="16px" />
                </Tooltip>
              </CopyToClipboard>
            )}
            {tooltip && (
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                }}
                leaveDelay={300}
                title={props.tooltip || ""}
                placement="top-end"
              >
                <IconButton>i</IconButton>
              </Tooltip>
            )}
          </label>
        )}
        <input
          {...inputProps}
          onChange={handleChange}
          onBlur={handleBlur}
          value={
            props.type === "number" ? Math.max(0, field.value) : field.value
          }
          className="textfield__input"
          onKeyDown={
            props.type === "number"
              ? (evt) =>
                  ["e", "E", "+", "-", ",", "."].includes(evt.key) &&
                  evt.preventDefault()
              : undefined
          }
          min={props.type === "number" ? 0 : undefined}
          step={props.type === "number" ? props.step : undefined}
        />
      </div>
      {error && error.length > 0 && (
        <span data-testid="textfield__error" className="textfield__error">
          {error}
        </span>
      )}
    </div>
  );
}
