import { HexCoder } from "@guardtime/common/lib/coders/HexCoder";
import { useState } from "react";
import BillList from "./components/Bill/BillList";
import Header from "./components/Header/Header";
import KeyPair from "./components/KeyPair/KeyPair";
import Order from "./components/Order/Order";
import Spacer from "./components/Spacer/Spacer";

interface IBillState {
  time: any;
  id: string;
  signature?: Uint8Array;
  predicate: string;
}

export interface IKeys {
  id: string;
  keyAlgorithm?: string;
  privateKey?: Uint8Array;
  publicKey?: Uint8Array;
  sha256?: string;
  sha512?: string;
}

export interface IGlobalState {
  id: string;
  privateKey?: Uint8Array;
  publicKey?: Uint8Array;
  transactionSignature?: Uint8Array;
  isValidSignature?: boolean;
  isSubmitted?: boolean;
  billStates?: [IBillState];
  billValue: number;
  billPredicate: string;
  bearerSignature: string;
  newPredicate: string;
  useKey?: string;
}

export interface IBillProps {
  setGlobalState: (e: any) => void;
  globalState: IGlobalState[];
  keys: IKeys[];
}

export interface IKeysProps {
  setKeys: (e: any) => void;
  keys: IKeys[];
}

function App() {
  const [globalState, setGlobalState] = useState<IGlobalState[]>([
    {
      id: "demo_bill1",
      billValue: 100,
      billPredicate: "",
      bearerSignature: "push true",
      newPredicate: "",
    },
    {
      id: "demo_bill2",
      billValue: 100,
      billPredicate:
        'push publickey "ed25519" hex "3f75cb8f3e692ac2e9a43bdb3d04d1bf8551b3190768f46dcfa379029a8686dd"\ncheck_signature',
      bearerSignature: "push $signature",
      newPredicate: "push $alice.publicKey\ncheck_signature",
      useKey: "alice",
    },
    {
      id: "demo_bill3",
      billValue: 100,
      billPredicate:
        'dup\nhash "sha256"\npush hash "sha256" hex "69f9e213169e7f07b72850ed9948d238685d0afe16875d4e6fddf60c576f93b1" ' +
        "\nequal\nverify\ncheck_signature",
      bearerSignature: "push $signature\npush $bob.publicKey",
      newPredicate:
        'dup\nhash "sha256"\npush $alice.sha256' +
        "\nequal\nverify\ncheck_signature",
      useKey: "bob",
    },
  ]);
  const [keys, setKeys] = useState<IKeys[]>([
    {
      id: "alice",
      keyAlgorithm: "Ed25519",
      privateKey: HexCoder.decode(
        "8682d5e5a42257d6d17bf8370ee56c4f13303f4e91737e1f5e78f1d7fa71a5d4"
      ),
      publicKey: HexCoder.decode(
        "3f75cb8f3e692ac2e9a43bdb3d04d1bf8551b3190768f46dcfa379029a8686dd"
      ),
      sha256: "677d5ceaa1d27779d5cb2e5ee68acae672c6594fdf9f50a0b79ca19d9664e807",
      sha512: "efffb68afd42e9dfd8be1fdeec04b78c72b43be3ce20cd78f59c0347c82ad00786caefc56282eea89cdbeb2119ca4ae83734c8dacc4b9407af5c7bb6be22babd"
    },
    {
      id: "bob",
      keyAlgorithm: "Ed25519",
      privateKey: HexCoder.decode(
        "a96a0d622d31b31511f019772bbb8b068426c18c073cbf5b22e30018b3e23617"
      ),
      publicKey: HexCoder.decode(
        "68888f3f1a132bafc572a1a8eba4e8b66415042cb547dadc0060ceccd2920f45"
      ),
      sha256: "69f9e213169e7f07b72850ed9948d238685d0afe16875d4e6fddf60c576f93b1",
      sha512: "0eb5218bb292b7bd515de2a365b7ec73bcfec81dc1bfec41cfd858f82414afdacfec7de1b4cdb194d9b947fa0f0380725ad81c31e549a002ce755a88e88f627b"
    },
  ]);

  return (
    <div className="app">
      <Header />
      <div className="container">
        <Spacer mt={32} />
        <div className="max-w-688 mar-auto">
          <h3 className="txt-algn-center">Welcome to the Alphabill Playground</h3>
          <h4 className="txt-algn-center">
            This playground is a simulation of the Alphabill scripting system.
            You can use it to familiarize yourself with how Alphabill transactions,
            predicates, and unlock scripts work.  You can also use it to test
            predicates for use in the test network.
          </h4>
        </div>
        <Spacer mt={64} />
        <Order
          keys={keys}
          globalState={globalState}
          setGlobalState={setGlobalState}
        />
        <Spacer mt={32} />
        <div className="flex-group-2">
          <div className="flex-group-2__item">
            <BillList
              globalState={globalState}
              setGlobalState={setGlobalState}
              keys={keys}
            />
          </div>
          <div className="flex-group-2__item">
            <KeyPair keys={keys} setKeys={setKeys} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
