import React from 'react';

import classNames from 'classnames';

import Spinner from '../Spinner/Spinner';

export interface IButtonProps {
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => void;
  variant?: 'primary' | 'link' | 'icon';
  working?: boolean;
  workingText?: string;
  block?: boolean;
  url?: string;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  className?: string;
  target?: string;
  lightLink?: boolean;
}

export default function Button(props: IButtonProps): JSX.Element {
  const className = classNames(
    'button', `button--${props.variant || 'primary'}`,
    {
      'button--block': props.block,
      'button--working': props.working,
      'button--link-light': props.lightLink
    },
    props.className
  );

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) => {
    if (props.onClick) {
      props.onClick(event);
    }
  };

  const TagName = props.url ? 'a' : 'button';

  return (
    <TagName
      {...(props.target && {target: props.target})}
      className={className}
      onClick={handleButtonClick}
      type={props.type}
      disabled={props.disabled}
      href={props.url ? props.url : undefined}
    >
      {props.working && props.workingText ? (
        props.workingText
      ) : props.working ? (
        <Spinner />
      ) : (
        props.children
      )}
    </TagName>
  );
}
