import { HexCoder } from "@guardtime/common/lib/coders/HexCoder";
import React, { useState } from "react";
import nextId from "react-id-generator";
import { ValidationError } from "yup";
import * as Yup from "yup";
import { Formik } from "formik";
import { utils, getPublicKey } from "@noble/ed25519";
import CryptoJS, { SHA256, SHA512 } from "crypto-js";

import Banner from "../Banner/Banner";
import { Form, FormContent } from "../Form/Form";
import Button from "../Button/Button";
import { extractFormikError } from "../../utils/utils";
import Spacer from "../Spacer/Spacer";
import Textfield from "../TextField/TextField";
import Select from "../Select/Select";
import { IKeysProps } from "../../App";
import AddIco from "../../assets/add.svg";
import CloseIco from "../../assets/close.svg";

export default function KeyPair(props: IKeysProps) {
  const [error, setError] = useState<string | undefined>(undefined);

  const renderBannerButtons = (id: string) => {
    return (
      <div className="flex">
        <img
          onClick={() =>
            props.setKeys([
              ...props.keys,
              {
                id: nextId("my_key"),
              },
            ])
          }
          src={AddIco}
          alt="Add"
          height="32px"
          className="button__icon"
        />
        {props.keys.length > 1 && (
          <>
            <Spacer mr={4} />
            <img
              onClick={() =>
                props.setKeys(props.keys.filter((item) => item.id !== id))
              }
              src={CloseIco}
              alt="Add"
              height="32px"
              className="button__icon"
            />
          </>
        )}
      </div>
    );
  };

  return (
    <div>
      {props.keys.map((item) => {
        return (
          <Banner
            key={item.id}
            headerComponents={renderBannerButtons(item.id)}
            title="Key Pair"
          >
            <Formik
              enableReinitialize={true}
              validateOnMount={true}
              validationSchema={Yup.object().shape({
                keyName: Yup.string().test("keyName", (value) => {
                  if (!value) {
                    return new ValidationError(
                      "Key name is required.",
                      false,
                      "keyName"
                    );
                  }

                  if (!value.match(/^[_$a-zA-z][_$a-zA-z0-9]*$/)) {
                    return new ValidationError(
                      "Key name must follow following pattern [_$a-zA-z][_$a-zA-z0-9]*.",
                      false,
                      "keyName"
                    );
                  }

                  return true;
                }),
                keyAlgorithm: Yup.string().required(
                  "Key Algorithm is required."
                ),
              })}
              initialValues={{
                sha256: item.sha256,
                sha512: item.sha512,
                keyName: item.id,
                keyAlgorithm: "Ed25519",
                privateKey: item.privateKey
                  ? utils.bytesToHex(item.privateKey)
                  : "",
                publicKey: item.publicKey
                  ? utils.bytesToHex(item.publicKey)
                  : "",
              }}
              onSubmit={async (values: any) => {
                setError(undefined);
                try {
                  const data = [];
                  for (const obj of props.keys) {
                    data.push(
                      obj.id === item.id
                        ? {
                            ...obj,
                            id: values.keyName,
                            keyAlgorithm: values.keyAlgorithm,
                            privateKey: HexCoder.decode(values.privateKey),
                            publicKey: HexCoder.decode(values.publicKey),
                            sha256: values.sha256.toString(CryptoJS.enc.Hex),
                            sha512: values.sha512.toString(CryptoJS.enc.Hex)
                          }
                        : obj
                    );
                  }

                  await props.setKeys(data);
                } catch (e) {
                  setError(((e as any) || "").toString());
                }
              }}
            >
              {(formikProps) => {
                const {
                  handleSubmit,
                  isSubmitting,
                  errors,
                  touched,
                  setFieldValue,
                  values,
                } = formikProps;

                const privateKey = item.privateKey
                  ? utils.bytesToHex(item.privateKey || [])
                  : undefined;
                const publicKey = item.publicKey
                  ? utils.bytesToHex(item.publicKey || [])
                  : undefined;

                return (
                  <form onSubmit={handleSubmit}>
                    <Form>
                      <FormContent>
                        <Textfield
                          id="keyName"
                          name="keyName"
                          label="Name"
                          type="text"
                          error={extractFormikError(errors, touched, [
                            "keyName",
                          ])}
                        />
                        <Select
                          label="Key Algorithm"
                          name="keyAlgorithm"
                          options={[
                            {
                              value: "Ed25519",
                              label: "Ed25519",
                            },
                          ]}
                          error={extractFormikError(errors, touched, [
                            "keyAlgorithm",
                          ])}
                        />
                        <Spacer mt={32} />
                        <div className="button--group">
                          <div className="button-ico-wrap">
                            <Button
                              type="submit"
                              disabled={
                                values.keyName === item.id &&
                                publicKey === values.publicKey &&
                                privateKey === values.privateKey &&
                                values.sha256 == item.sha256 &&
                                values.sha512 == item.sha512
                              }
                              working={isSubmitting}
                            >
                              Save
                            </Button>
                          </div>
                          <div className="button-ico-wrap">
                            <Button
                              type="button"
                              onClick={async () => {
                                const privateKey = utils.randomPrivateKey();
                                const publicKey = await getPublicKey(privateKey);
                                setFieldValue(
                                  "privateKey",
                                  utils.bytesToHex(privateKey)
                                );
                                setFieldValue(
                                  "publicKey",
                                  utils.bytesToHex(publicKey)
                                );
                                setFieldValue(
                                  "sha256",
                                  SHA256(CryptoJS.enc.Hex.parse(utils.bytesToHex(publicKey)))
                                );
                                setFieldValue(
                                  "sha512",
                                  SHA512(CryptoJS.enc.Hex.parse(utils.bytesToHex(publicKey)))
                                );
                              }}
                            >
                              Generate
                            </Button>
                          </div>
                        </div>
                        {error && (
                          <div
                            style={{
                              color: "#EF476F",
                              fontWeight: 600,
                              marginTop: "5px",
                            }}
                          >
                            {error}
                          </div>
                        )}
                        <Textfield
                          id="privateKey"
                          name="privateKey"
                          label="Private Key"
                          type="text"
                          value={privateKey}
                          tooltip={"Use $" + item.id + ".privateKey reference in code"}
                          copyButtonValue={"$" + item.id + ".privateKey"}
                          copyTooltip={"Copy $" + item.id + ".privateKey to reference in code"}
                        />
                        <Textfield
                          id="publicKey"
                          name="publicKey"
                          label="Public Key"
                          type="text"
                          value={publicKey}
                          tooltip={"Use $" + item.id + ".publicKey reference in code"}
                          copyButtonValue={"$" + item.id + ".publicKey"}
                          copyTooltip={"Copy $" + item.id + ".publicKey to reference in code"}
                          onChange={(e) => {
                            setFieldValue(
                              "sha256",
                              SHA256(CryptoJS.enc.Hex.parse(e.target.value.toString()))
                            );
                            setFieldValue(
                              "sha512",
                              SHA512(CryptoJS.enc.Hex.parse(e.target.value.toString()))
                            );
                          }}
                        />
                        <Spacer mt={32} />
                        <Textfield
                          id="sha256"
                          name="sha256"
                          label="Public Key SHA256 Hash"
                          type="text"
                          tooltip={"Use $" + item.id + ".sha256 reference in code"}
                          copyButtonValue={"$" + item.id + ".sha256"}
                          copyTooltip={"Copy $" + item.id + ".sha256 to reference in code"}
                          error={extractFormikError(errors, touched, [
                            "sha256",
                          ])}
                        />
                        <Textfield
                          id="sha512"
                          name="sha512"
                          label="Public Key SHA512 Hash"
                          type="text"
                          tooltip={"Use $" + item.id + ".sha512 reference in code"}
                          copyButtonValue={"$" + item.id + ".sha512"}
                          copyTooltip={"Copy $" + item.id + ".sha512 to reference in code"}
                          error={extractFormikError(errors, touched, [
                            "sha512",
                          ])}
                        />
                      </FormContent>
                    </Form>
                  </form>
                );
              }}
            </Formik>
          </Banner>
        );
      })}
    </div>
  );
}
