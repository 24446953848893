import { HexCoder } from "@guardtime/common/lib/coders/HexCoder.js";
import { HashAlgorithm } from "@guardtime/common/lib/hash/HashAlgorithm.js";
import { SyncDataHasher } from "@guardtime/common/lib/hash/SyncDataHasher.js";
import { compareUint8Arrays } from "@guardtime/common/lib/utils/Array.js";
import { verify } from "@noble/ed25519";
import {getHashInfoById, getSignatureSchemeInfoById} from "./schemes.js";
import {Result, ResultCode} from "@guardtime/common/lib/verification/Result.js";

export function ShinyDup() {
    this.exec = async (env, stack) => {
        const top = stack.pop();
        stack.push(top);
        stack.push(top);
    };

    this.toString = () => {
        return "dup";
    }
}

export function ShinyHash(algoId) {
    this.algoId = algoId;
    this.info = getHashInfoById(this.algoId);
    this.exec = async (env, stack) => {
        const top = stack.pop();

        if (typeof top.hash !== 'function') {
            throw "Unhashable element";
        }
        stack.push(top.hash(this.algoId));
    }

    this.toString = () => {
        return 'hash "' + this.info.name + '"';
    }
}

export function ShinyCheckSignature() {
    this.exec = async (env, stack) => {
        const pub = stack.pop();
        const sig = stack.pop();

        if (!(pub instanceof TypePublicKey)) {
            throw "Top element of the stack is not a public key.";
        }

        if (!(sig instanceof TypeSignature)) {
            throw "Second element on top of the stack is not a signature value.";
        }

        if (sig.algoId !== pub.algoId) {
            throw "Signature and public keys are of different scheme.";
        }

        const tx = env.transactionOrder;
        if (!tx) {
            throw "Transaction order missing or invalid.";
        }

        const isValid = await verify(sig.val, tx, pub.val);

        stack.push(new TypeBoolean(isValid));
    }

    this.toString = () => {
        return "check_signature";
    }
}

export function ShinyEquals() {
    this.exec = async (env, stack) => {
        let a = stack.pop();
        let b = stack.pop();

        stack.push(new TypeBoolean(a.equals(b)));
    }

    this.toString = () => {
        return "equal";
    }
}

export function ShinyVerify() {
    this.exec = async (env, stack) => {
        let top = stack.pop();

        if (!(top instanceof TypeBoolean) || !top.val) {
            throw "Verification of top element failed."
        }
    }

    this.toString = () => {
        return "verify";
    }
}

export function ShinySwap() {
    this.exec = async (env, stack) => {
        const top = stack.pop();
        const next = stack.pop();
        stack.push(top);
        stack.push(next);
    }

    this.toString = () => {
        return "swap";
    }
}

export function ShinyDrop() {
    this.exec = async (env, stack) => {
        stack.pop();
    }

    this.toString = () => {
        return "drop";
    }
}

export function ShinyNot() {
    this.exec = async (env, stack) => {
        const top = stack.pop();

        if (typeof top.not !== "function") {
            throw "Operation not is not implemented for type";
        }

        stack.push(top.not());
    }

    this.toString = () => {
        return "not";
    }
}

function getValueHash(algoId, value) {
    const hashAlgorithm = {
        0x01: HashAlgorithm.SHA2_256,
        0x02: HashAlgorithm.SHA2_512
    }[algoId];

    if (!hashAlgorithm) throw "Hash algoritm not implemented : 0x" + algoId;

    let hash = new SyncDataHasher(hashAlgorithm);
    hash.update(value);
    const digest = hash.digest().value;

    return new TypeHash(algoId, digest);
}

export function TypeInt(val) {
    this.val = val;
    this.toString = () => {
        return "integer " + this.val;
    }

    this.equals = (other) => {
        if (!(other instanceof TypeInt)) {
            throw "Comparing integer value with an incompatible type."
        }

        return other.val === this.val;
    }
}

export function TypeBoolean(val) {
    switch (typeof val) {
        case 'number':
            switch (val) {
                case 0:
                case 1:
                    this.val = !!val;
                    break;
                default:
                    throw "Invalid boolean value";
            }
            break;
        case 'boolean':
            this.val = val;
            break;
        default:
            throw "Invalid type of argument to TypeBoolean constructor.";
    }

    this.toString = () => {
        return "boolean " + this.val;
    }

    this.equals = (other) => {
        if (!(other instanceof TypeBoolean)) {
            throw "Comparing boolean value with an incompatible type.";
        }

        return other.val === this.val;
    }

    this.not = () => {
        return new TypeBoolean(!this.val);
    }
}


export function TypeHash(algoId, val) {
    this.val = val;
    this.algoId = algoId;
    this.info = getHashInfoById(algoId)

    if (this.info.len !== this.val.length) {
        throw "Invalid hash value length.";
    }

    this.toString = () => {
        return 'hash "' + this.info.name + '" hex "' + HexCoder.encode(this.val) + '"';
    }

    this.equals = function (other) {
        if (!(other instanceof TypeHash)) {
            throw "Comparing hash value with incomatible type.";
        }

        if (other.algoId !== this.algoId) {
            throw "Comparing hash values from different algorithms."
        }

        return compareUint8Arrays(this.val, other.val);
    }

    this.hash = (algoId) => {
        return getValueHash(algoId, this.val);
    }
}

export function TypePublicKey(algoId, val) {
    this.val = val;
    this.algoId = algoId;
    this.info = getSignatureSchemeInfoById(algoId);

    if (this.info.pubKeyLen !== this.val.length) {
        throw "Invalid public key length.";
    }

    this.toString = () => {
        return 'public key "' + this.info.name + '" hex "' + HexCoder.encode(this.val) + '"';
    }

    this.equals = (other) => {
        if (!(other instanceof TypePublicKey)) {
            throw "Public key can not be equal with an incompatible type.";
        }
        if (other.algoId !== this.algoId) {
            throw "Comparing public keys from different algorithms.";
        }

        return compareUint8Arrays(this.val, other.val);
    }

    this.hash = (algoId) => {
        return getValueHash(algoId, this.val);
    }
}

export function TypeSignature(algoId, val) {
    this.val = val;
    this.algoId = algoId;
    this.info = getSignatureSchemeInfoById(algoId);

    if (this.val.length !== this.info.signatureLen) {
        throw "Invalid signature length.";
    }

    this.toString = () => {
        return 'signature "' + this.info.name + '" hex "' + HexCoder.encode(this.val) + '"';
    }

    this.equals = (other) => {
        if (!(other instanceof TypeSignature)) {
            throw "Signature can not be equal with an incompatible type.";
        }

        if (other.algoId !== this.algoId) {
            throw "Comparing signatures from different algorithms.";
        }

        return compareUint8Arrays(other.val, this.val);
    }

    this.hash = (algoId) => {
        return getValueHash(algoId, this.val);
    }
}

export function ShinyPush(val) {
    this.value = val;
    this.exec = async (env, stack) => {
        stack.push(this.value);
    }

    this.toString = () => {
        return "push " + this.value.toString();
    }
}

export function ShinyIf() {
    this.when = [];
    this.else = [];

    this.exec = async (env, stack) => {
        const top = stack.pop();
        if (!(top instanceof TypeBoolean)) {
            throw "Top value of stack not a boolean";
        }
        const branch = top.val ? this.when : this.else;
        await interpret(branch, env, stack);
    }

    this.toString = () => {
        return "if";
    }
}

export function ShinyElse() {
    this.exec = async () => {
        throw "Else not implemented.";
    }

    this.toString = () => {
        return "else";
    }
}

export function ShinyEndIf() {
    this.exec = async () => {
        throw "Endif not implemented.";
    }

    this.toString = () => {
        return "end if";
    }
}

function treeify(program) {
    const np = [];
    const nesting = [np];
    const peek = (arr) => arr[arr.length - 1];

    for (let i = 0; i < program.length; i++) {
        const inst = program[i];

        if (inst instanceof ShinyIf) {
            peek(nesting).push(inst);
            nesting.push(inst.when);
            continue;
        }

        if (inst instanceof ShinyElse) {
            nesting.pop();
            const ifbranch = peek(nesting);
            const ifinstr = peek(ifbranch);
            nesting.push(ifinstr.else);
            continue;
        }

        if (inst instanceof ShinyEndIf) {
            nesting.pop();
            continue;
        }

        peek(nesting).push(inst);
    }

    return np;
}

// FIXME Check binary lengths on reading data
export function loadShiny(bin, returnTree) {
    bin = Array.from(bin);
    const magic = bin[0];

    if (magic !== 0x53) {
        console.log('Not a shiny bin', bin.map(x => '0x' + x.toString(16)));
        throw "Not a shiny binary!";
    }
    bin.shift();

    const program = [];

    try {
        while (bin.length > 0) {
            const op = bin.shift();
            let inst;
            switch (op) {
                case 0x76: {
                    inst = new ShinyDup();
                    break;
                }
                case 0xa8: {
                    if (bin.length === 0) {
                        throw "Unexpected end of input";
                    }
                    const algoId = bin.shift();
                    inst = new ShinyHash(algoId);
                    break;
                }
                case 0x4f: {
                    if (bin.length === 0) {
                        throw "Unexpected end of input";
                    }
                    const algoId = bin.shift();
                    const info = getHashInfoById(algoId);
                    if (bin.length < info.len) {
                        throw "Not enough bytes for digest."
                    }
                    const digest = new Uint8Array(bin.splice(0, info.len));

                    const val = new TypeHash(algoId, digest);
                    inst = new ShinyPush(val);

                    break;
                }
                case 0x55: {
                    if (bin.length === 0) {
                        throw "Unexpected end of input";
                    }
                    const algoId = bin.shift();
                    const info = getSignatureSchemeInfoById(algoId);
                    if (bin.length < info.pubKeyLen) {
                        throw "Not enough bytes for public key."
                    }
                    const pubKey = new Uint8Array(bin.splice(0, info.pubKeyLen));

                    const val = new TypePublicKey(algoId, pubKey);
                    inst = new ShinyPush(val);

                    break;
                }
                case 0x54: {
                    if (bin.length === 0) {
                        throw "Unexpected end of input";
                    }
                    const algoId = bin.shift();
                    const info = getSignatureSchemeInfoById(algoId);

                    if (bin.length < info.signatureLen) {
                        throw "Not enough bytes for signature."
                    }
                    const signature = new Uint8Array(bin.splice(0, info.signatureLen));

                    const val = new TypeSignature(algoId, signature);
                    inst = new ShinyPush(val);

                    break;
                }
                case 0xac: {
                    inst = new ShinyCheckSignature();

                    break;
                }
                case 0x87: {
                    inst = new ShinyEquals();
                    break;
                }
                case 0x69: {
                    inst = new ShinyVerify();
                    break;
                }
                case 0x63: {
                    inst = new ShinyIf();
                    break;
                }
                case 0x67: {
                    inst = new ShinyElse();
                    break;
                }
                case 0x68: {
                    inst = new ShinyEndIf();
                    break;
                }
                case 0x7c: {
                    inst = new ShinySwap();
                    break;
                }
                case 0x75: {
                    inst = new ShinyDrop();
                    break;
                }
                case 0x91: {
                    inst = new ShinyNot();
                    break;
                }
                case 0x01: {
                    if (bin.length < 8) {
                        throw "Not enough bytes for int value";
                    }

                    const val = new TypeInt(BigInt.asIntN(64, bin.splice(0, 8).reduce((p, c) => BigInt(p) << 8n | BigInt(c), 0n)));

                    inst = new ShinyPush(val);
                    break;
                }
                case 0x51: {
                    if (bin.length === 0) {
                        throw "Unexpected end of input";
                    }
                    const val = bin.shift();

                    inst = new ShinyPush(new TypeBoolean(val));
                    break;
                }
                default:
                    throw "Unexpected instruction 0x" + op.toString(16);
            }

            if (inst) {
                program.push(inst);
            }
        }
    } catch (e) {
        console.warn(e)
        throw e;
    }

    return returnTree === undefined || returnTree ? treeify(program) : program;
}

/** Interpretes a Shiny script. */
export async function interpret(program, env, stack) {
    for (const inst of program) {
        await inst.exec(env, stack);
    }
}

class Stack extends Array {
    pop() {
        if (this.length === 0) {
            throw "Stack underflow"
        }

        return super.pop();
    }
}

/** Verifies the bearer signature given the signature both represented as Shiny scripts.*/
export async function interpretBillScript(signatureScript, bearerScript, env) {
    let stack = new Stack();
    try {
        await interpret(signatureScript, env, stack);
        await interpret(bearerScript, env, stack);

        // At this point there must be only one element in the stack.
        if (stack.length !== 1) {
            return new Result('', ResultCode.FAIL, 'Stack does not contain only one element');
        }

        let top = stack.pop();

        // The only remaining element must be a boolean.
        if (!(top instanceof TypeBoolean)) {
            return new Result('', ResultCode.FAIL, 'Top element of the stack is not a boolean value');
        }

        if(!top.val) {
            return new Result('', ResultCode.FAIL, 'Signature is not valid')
        }
        return new Result('', ResultCode.OK, 'Success');
    } catch (e) {
        // TODO: Display only Shiny error messages, otherwise return "internal error".
        return new Result('', ResultCode.FAIL, (e || '').toString());
    }
}
